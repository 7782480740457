/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/11/2021
 * @Example
 */

import Api from "../../../services/api";
import {put, cancel, take, takeLatest} from "redux-saga/effects";
import {types} from "./W09F2215_actions";
import {delay} from "redux-saga";

export default function W09F2215Sagas() {
    return [watchGetForm(), watchGetListRelative(), watchUpdate(), watchGetGender()];
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2205/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error ");
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2215_GET_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getListRelative(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2210/load-list-relative", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F2215_GET_LIST_RELATIVE_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get list relative error");
    }
}

export function* watchGetListRelative() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2215_GET_LIST_RELATIVE, getListRelative);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getGender(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/standard/get-gender");
        if (response && response.data) {
            yield put({
                type: types.W09F2215_GET_GENDER_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get gender error");
    }
}

export function* watchGetGender() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2215_GET_GENDER, getGender);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* update(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2215/update", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("update error");
    }
}

export function* watchUpdate() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2215_UPDATE, update);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

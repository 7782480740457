/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/3/2021
 * @Example
 */
export const types = {
    W09F3005_LOAD_GROUP_DETAIL:         "W09F3005_LOAD_GROUP_DETAIL",
    W09F3005_LOAD_GROUP_DETAIL_SUCCESS: "W09F3005_LOAD_GROUP_DETAIL_SUCCESS",
    W09F3005_LOAD_PROCESS:              "W09F3005_LOAD_PROCESS",
    W09F3005_LOAD_PROCESS_SUCCESS:      "W09F3005_LOAD_PROCESS_SUCCESS",
    W09F3005_SAVE:                      "W09F3005_SAVE",
    W09F3005_UPDATE:                    "W09F3005_UPDATE",
};

export function loadGroupDetail (params, cb) {
    return {
        type: types.W09F3005_LOAD_GROUP_DETAIL,
        params,
        cb
    };
}

export function loadProcess (params, cb) {
    return {
        type: types.W09F3005_LOAD_PROCESS,
        params,
        cb
    };
}

export function save (params, cb) {
    return {
        type: types.W09F3005_SAVE,
        params,
        cb
    };
}

export function update (params, cb) {
    return {
        type: types.W09F3005_UPDATE,
        params,
        cb
    };
}


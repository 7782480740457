/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/11/2021
 * @Example
 */

import Api                             from "../../../services/api";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types}                         from "./W09F2291_actions";
import {delay}                         from "redux-saga";

export default function W09F2291Sagas() {
    return [
        watchGetReasons(),
        watchGetColumns(),
        watchGetCaptions(),
        watchGetGrids(),
        watchGetEmployee(),
        watchSave(),
        watchEdit(),
        watchConfirmSave(),
        watchConfirmEdit(),
    ];
}

export function* getReasons(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/W09F2291/load-reason");
        if (response && response.data) {
            yield put({
                type: types.W09F2291_GET_CBO_REASON_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get reason error", e);
    }
}

export function* watchGetReasons() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2291_GET_CBO_REASON, getReasons);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getColumns(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/W09F2291/load-collumn");
        if (response && response.data) {
            yield put({
                type: types.W09F2291_GET_COLUMN_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get collumn error");
    }
}

export function* watchGetColumns() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2291_GET_COLUMN, getColumns);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCaptions(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/W09F2291/load-caption");
        if (response && response.data) {
            yield put({
                type: types.W09F2291_GET_CAPTION_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get caption error");
    }
}

export function* watchGetCaptions() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2291_GET_CAPTION, getCaptions);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getGrids(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/W09F2291/load-grid", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get data grid error");
    }
}

export function* watchGetGrids() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2291_GET_GRID, getGrids);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getEmployee(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/W09F2291/load-employee", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get employee error");
    }
}

export function* watchGetEmployee() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2291_GET_EMPLOYEE, getEmployee);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/W09F2291/save", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("save error");
    }
}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2291_SAVE, save);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* edit(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/W09F2291/edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit error");
    }
}

export function* watchEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2291_EDIT, edit);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* confirmSave(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/W09F2291/confirm-save", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("confirm save error");
    }
}

export function* watchConfirmSave() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2291_CONFIRM_SAVE, confirmSave);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* confirmEdit(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/W09F2291/confirm-edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("confirm edit error");
    }
}

export function* watchConfirmEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2291_CONFIRM_EDIT, confirmEdit);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}



/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/25/2020
 * @Example
 */

import Api from "../../../services/api";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types} from "./W09F3005_actions";
import {delay} from "redux-saga";

export default function W09F3005Sagas () {
    return [
        watchGetProcess(),
        watchGetGroupDetail(),
        watchSave(),
        watchUpdate(),
    ];
}

//get group detail
export function* getGroupDetail (data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f3005/load-group-detail", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F3005_LOAD_GROUP_DETAIL_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("W09F3005 get group detail error", e);
    }
}

export function* watchGetGroupDetail () {
    while (true) {
        const watcher = yield takeLatest(types.W09F3005_LOAD_GROUP_DETAIL, getGroupDetail);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//get process
export function* getProcess (data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f3005/load-process", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F3005_LOAD_PROCESS_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("W09F3005 get process error");
    }
}

export function* watchGetProcess () {
    while (true) {
        const watcher = yield takeLatest(types.W09F3005_LOAD_PROCESS, getProcess);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//save
export function* save (data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w09f3005/add", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("W09F3005 save error");
    }
}

export function* watchSave () {
    while (true) {
        const watcher = yield takeLatest(types.W09F3005_SAVE, save);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//update
export function* update (data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f3005/edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("W09F3005 update error");
    }
}

export function* watchUpdate () {
    while (true) {
        const watcher = yield takeLatest(types.W09F3005_UPDATE, update);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}



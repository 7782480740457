/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 20/12/2021
 * @Example
 */

import { types } from './W09F2310_actions';

const initialState = {
    getData: { rows: [], total: 0 },
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2310_GET_DATA_SUCCESS: {
            return {
                ...state,
                getData: action.data,
            };
        }
        default:
            return state;
    }
}

/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 20/12/2021
 * @Example
 */

import { delay } from 'redux-saga';
import { cancel, take, takeEvery, takeLatest, put } from 'redux-saga/effects';
import Api from '../../../services/api';
import { types } from './W09F2310_actions';
import Config from '../../../config';

export default function W09F2310Sagas() {
    return [watchGetData(), watchSignature(), watchCancelSignature(), watchViewReport()];
}

export function* getData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2310/load-grid', data.params);
        if (response?.data) {
            yield put({ type: types.W09F2310_GET_DATA_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get data error');
    }
}

export function* watchGetData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2310_GET_DATA, getData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* signature(data) {
    try {
        yield delay(300);
        const response = yield Api.post('/w09f2310/signatures', data.params);
        if (response?.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('signature error');
    }
}

export function* watchSignature() {
    while (true) {
        const watcher = yield takeEvery(types.W09F2310_SIGNATURE, signature);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* cancelSignature(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2310/cancel-signatures', data.params);
        if (response?.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('cancel signature error');
    }
}

export function* watchCancelSignature() {
    while (true) {
        const watcher = yield takeEvery(types.W09F2310_CANCEL_SIGNATURE, cancelSignature);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* viewReport(data) {
    try {
        yield delay(300);
        const response = yield Api.putExport('/w09f2310/view-report', data.params);
        if (response && response.ok) {
            let _res = null;
            if (response.type === 'json') {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                const content = yield response.data.blob();
                const _fileName = response.filename;
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    } catch (e) {
        console.log('view report error');
    }
}

export function* watchViewReport() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2310_VIEW_REPORT, viewReport);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

import { delay } from "redux-saga";
import Api from "../../../services/api";
import { types } from "./W09F2290_actions";
import { cancel, take, takeLatest } from "redux-saga/effects";
import _ from "lodash";

export default function W09F2290Sagas() {
    return [
        watchLoadDataGrid(),
        watchDeleteRow()
    ];
}

export function* loadDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2290/load-form", data.params);
        if (response) {
            const getData =  _.get(response, "data", {});
            const getMessageErr =  _.get(response, "message", "") || _.get(getData,"Message", "");
            let err = "";
            let dataRes = null;
            if(getMessageErr) {
                err =   getMessageErr;
            } else if(!!_.size(getData)) {
                dataRes =  getData;
            }
            data.cb(err, dataRes);
        } else {
            data.cb(response, null);
        }
    } catch (e) {
        console.log("load data grid error", e);
    }
}

export function* watchLoadDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2290_LOAD_DATA_GRID, loadDataGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteRow(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w09f2290/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("deleting row error", e);
    }
}

export function* watchDeleteRow() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2290_DELETE_ROW, deleteRow);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
/**
 * @copyright 2021 @ DigiNet
 * @author TRANGHOANG
 * @create 10/09/2021
 * @Example
 */

export const types = {
    W09F2270_LOAD_GRID: "W09F2270_LOAD_GRID",
    W09F2270_LOAD_GRID_SUCCESS: "W09F2270_LOAD_GRID_SUCCESS",
    W09F2270_DELETE_ROW_DATA: "W09F2270_DELETE_ROW_DATA",

};

export function loadGrid(params, cb) {
    return {
        type: types.W09F2270_LOAD_GRID,
        params,
        cb
    }
}


export function deleteRowData(params, cb) {
    return {
        type: types.W09F2270_DELETE_ROW_DATA,
        params,
        cb
    }
}



/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 4/9/2020
 * @Example
 */
export const types = {
  W09F9000_LOAD_USER_MANUAL_LEFT: "W09F9000_LOAD_USER_MANUAL_LEFT",
  W09F9000_LOAD_USER_MANUAL_LEFT_SUCCESS: "W09F9000_LOAD_USER_MANUAL_LEFT_SUCCESS",
  W09F9000_LOAD_USER_MANUAL_RIGHT: "W09F9000_LOAD_USER_MANUAL_RIGHT",
  W09F9000_LOAD_USER_MANUAL_RIGHT_SUCCESS: "W09F9000_LOAD_USER_MANUAL_RIGHT_SUCCESS",
};

export function loadUserManualLeft(cb) {
  return {
    type: types.W09F9000_LOAD_USER_MANUAL_LEFT,
    cb
  };
}

export function loadUserManualRight(params, cb) {
  return {
    type: types.W09F9000_LOAD_USER_MANUAL_RIGHT,
    params,
    cb
  };
}


// export function loadAppStatus(params, cb) {
//   return {
//     type: types.W09F2060_LOAD_APPROVAL_STATUS,
//     params,
//     cb
//   };
// }

// export function loadCboDisRewardForm(cb) {
//   return {
//     type: types.W09F2060_LOAD_CBO_DISREWARD_FORM,
//     cb
//   };
// }

export const types = {
    W09F2290_LOAD_DATA_GRID: "W09F2290_LOAD_DATA_GRID",
    W09F2290_DELETE_ROW: "W09F2290_DELETE_ROW",
};

export function loadDataGrid(params, cb) {
    return {
        type: types.W09F2290_LOAD_DATA_GRID,
        params,
        cb
    };
}

export function deleteRow(params, cb) {
    return {
        type: types.W09F2290_DELETE_ROW,
        params,
        cb
    };
}

/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/25/2020
 * @Example
 */

import Api from "../../../services/api";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types} from "./W09F3000_actions";
import {delay} from "redux-saga";

export default function W09F3000Sagas () {
    return [
        watchGetGrid(),
        watchDeleteData(),
    ];
}

//get data grid
export function* getGrid (data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f3000/load-form", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F3000_LOAD_GRID_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("W09F3000 data grid error");
    }
}

export function* watchGetGrid () {
    while (true) {
        const watcher = yield takeLatest(types.W09F3000_LOAD_GRID, getGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

//deleteData
export function* deleteData (data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w09f3000/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("W09F3000 delete data error");
    }
}

export function* watchDeleteData () {
    while (true) {
        const watcher = yield takeLatest(types.W09F3000_DELETE_DATA, deleteData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


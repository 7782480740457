/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 13/09/2021
 * @Example
 */

import { delay } from "redux-saga";
import Api from "../../../services/api";
import { types } from "./W09F2280_actions";
import { cancel, put, take, takeLatest } from "redux-saga/effects";

export default function W09F2280Sagas() {
    return [
        watchGetDataGrid(),
        watchDeleteRow()
    ];
}

export function* getDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2280/load-form", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F2280_GET_DATA_GRID_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get data grid error", e);
    }
}

export function* watchGetDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2280_GET_DATA_GRID, getDataGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteRow(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w09f2280/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get data grid error", e);
    }
}

export function* watchDeleteRow() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2280_DELETE_ROW, deleteRow);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/11/2021
 * @Example
 */

export const types = {
    W09F2215_GET_LIST_RELATIVE        : "W09F2215_GET_LIST_RELATIVE",
    W09F2215_GET_LIST_RELATIVE_SUCCESS: "W09F2215_GET_LIST_RELATIVE_SUCCESS",
    W09F2215_GET_FORM                 : "W09F2215_GET_FORM",
    W09F2215_GET_GENDER               : "W09F2215_GET_GENDER",
    W09F2215_GET_GENDER_SUCCESS       : "W09F2215_GET_GENDER_SUCCESS",
    W09F2215_UPDATE                   : "W09F2215_UPDATE",
};

export function getListRelative(params, cb) {
    return {
        type: types.W09F2215_GET_LIST_RELATIVE,
        cb,
        params
    };
}

export function getForm(params, cb) {
    return {
        type: types.W09F2215_GET_FORM,
        cb,
        params
    };
}

export function getGender(cb) {
    return {
        type: types.W09F2215_GET_GENDER,
        cb,
    };
}

export function update(params, cb) {
    return {
        type: types.W09F2215_UPDATE,
        cb,
        params
    };
}



/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 4/9/2020
 * @Example
 */

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F9000_actions";
import { delay } from "redux-saga";

export default function W09F9000Sagas() {
  return [
    watchGetManualLeftPanel(),
    watchGetManualRightPanel()
  ];
}

export function* getManualLeftPanel(data) {
  try {
    yield delay(300);
    const response = yield Api.get("/w09f9000/load-user-manual-left-panel");
    if (response && response.data) {
      yield put({
        type: types.W09F9000_LOAD_USER_MANUAL_LEFT_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get manual left error");
  }
}

export function* watchGetManualLeftPanel() {
  while (true) {
    const watcher = yield takeLatest(types.W09F9000_LOAD_USER_MANUAL_LEFT, getManualLeftPanel);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getManualRightPanel(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f9000/load-user-manual-right-panel", data.params);
    if (response && response.data) {
      yield put({
        type: types.W09F9000_LOAD_USER_MANUAL_RIGHT_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get manual right error");
  }
}

export function* watchGetManualRightPanel() {
  while (true) {
    const watcher = yield takeLatest(types.W09F9000_LOAD_USER_MANUAL_RIGHT, getManualRightPanel);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}


// export function* editData(data) {
//   try {
//     yield delay(300);
//     const response = yield Api.put("/w09f2060/edit", data.params);
//     if (response && response.data) {
//       data.cb && data.cb(null, response.data);
//     } else {
//       data.cb && data.cb(response, null);
//     }
//   } catch (e) {
//     console.log("edit data error");
//   }
// }

// export function* watchEditData() {
//   while (true) {
//     const watcher = yield takeLatest(types.W09F2060_EDIT_DATA, editData);
//     yield take(["LOGOUT", "NETWORK"]);
//     yield cancel(watcher);
//   }
// }

// export function* saveData(data) {
//   try {
//     yield delay(300);
//     const response = yield Api.put("/w09f2060/save", data.params);
//     if (response && response.data) {
//       data.cb && data.cb(null, response.data);
//     } else {
//       data.cb && data.cb(response, null);
//     }
//   } catch (e) {
//     console.log("edit data error");
//   }
// }

// export function* watchSaveData() {
//   while (true) {
//     const watcher = yield takeLatest(types.W09F2060_SAVE_DATA, saveData);
//     yield take(["LOGOUT", "NETWORK"]);
//     yield cancel(watcher);
//   }
// }

// //deleteData
// export function* deleteData(data) {
//   try {
//     yield delay(300);
//     const response = yield Api.put("/w09f2060/delete", data.params);
//     if (response && response.data) {
//       data.cb && data.cb(null, response.data);
//     } else {
//       data.cb && data.cb(response, null);
//     }
//   } catch (e) {
//     console.log("delete data error");
//   }
// }

// export function* watchDeleteData() {
//   while (true) {
//     const watcher = yield takeLatest(types.W09F2060_DELETE_DATA, deleteData);
//     yield take(["LOGOUT", "NETWORK"]);
//     yield cancel(watcher);
//   }
// }


/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/3/2021
 * @Example
 */
export const types = {
    W09F3000_LOAD_GRID:         "W09F3000_GET_DATA_GRID",
    W09F3000_LOAD_GRID_SUCCESS: "W09F3000_GET_DATA_GRID_SUCCESS",
    W09F3000_DELETE_DATA:       "W09F3000_DELETE_DATA",
};

export function loadGrid (params, cb) {
    return {
        type: types.W09F3000_LOAD_GRID,
        params,
        cb
    };
}

export function deleteGrid (params, cb) {
    return {
        type: types.W09F3000_DELETE_DATA,
        params,
        cb
    };
}


/**
 * @copyright 2021 @ DigiNet
 * @author TRANGHOANG
 * @create 13/09/2021
 * @Example
 */

export const types = {
    W09F2281_LOAD_FORM_POPUP: "W09F2281_LOAD_FORM_POPUP",
    W09F2281_LOAD_FORM_POPUP_SUCCESS: "W09F2281_LOAD_FORM_POPUP_SUCCESS",
    W09F2281_LOAD_EMPLOYEE: "W09F2281_LOAD_EMPLOYEE",
    W09F2281_LOAD_CHOOSE_EMPLOYEE: "W09F2281_LOAD_CHOOSE_EMPLOYEE",
    W09F2281_ADD: "W09F2281_ADD",
    W09F2281_EDIT: "W09F2281_EDIT"
};

export function loadForm(params, cb) {
    return {
        type: types.W09F2281_LOAD_FORM_POPUP,
        params,
        cb
    };
}

export function loadEmployee(params, cb) {
    return {
        type: types.W09F2281_LOAD_EMPLOYEE,
        params,
        cb
    };
}

export function loadChooseEmployee(cb) {
    return {
        type: types.W09F2281_LOAD_CHOOSE_EMPLOYEE,
        cb
    };
}

export function add(params, cb) {
    return {
        type: types.W09F2281_ADD,
        params,
        cb
    };
}

export function edit(params, cb) {
    return {
        type: types.W09F2281_EDIT,
        params,
        cb
    };
}

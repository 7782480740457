/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 12/10/2021
 * @Example
 */

import { delay } from 'redux-saga';
import { cancel, take, takeLatest } from 'redux-saga/effects';
import Api from '../../../services/api';
import { types } from './W09F2292_actions';

export default function W09F2292Sagas() {
    return [watchGetDynamicColumnSalary(), watchGetDynamicColumnCaption()];
}

export function* getDynamicColumnSalary(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2080/get-dynamic-column-salary', data.params);
        if (response?.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get dynamic column salary error');
    }
}

export function* watchGetDynamicColumnSalary() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2292_GET_DYNAMIC_COLUMN_SALARY, getDynamicColumnSalary);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getDynamicColumnCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2151/load-cbo-code', data.params);
        if (response?.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get dynamic column caption error');
    }
}

export function* watchGetDynamicColumnCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2292_GET_DYNAMIC_COLUMN_CAPTION, getDynamicColumnCaption);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

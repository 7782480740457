/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 13/09/2021
 * @Example
 */

export const types = {
    W09F2280_GET_DATA_GRID: "W09F2280_GET_DATA_GRID",
    W09F2280_GET_DATA_GRID_SUCCESS: "W09F2280_GET_DATA_GRID_SUCCESS",
    W09F2280_DELETE_ROW: "W09F2280_DELETE_ROW"
};

export function getDataGrid(params, cb) {
    return {
        type: types.W09F2280_GET_DATA_GRID,
        params,
        cb
    };
}

export function deleteRow(params, cb) {
    return {
        type: types.W09F2280_DELETE_ROW,
        params,
        cb
    };
}



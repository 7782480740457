/**
 * @copyright 2021 @ DigiNet
 * @author TRANGHOANG
 * @create 10/09/2021
 * @Example
 */
import { types } from "./W09F2270_actions";
const initialState = {
  dataGrid: [],
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case types.W09F2270_LOAD_GRID_SUCCESS: {
      return {
        ...state, dataGrid: action.data,
      };
    }
    default:
      return state;
  }
}

/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 20/12/2021
 * @Example
 */

export const types = {
    W09F2310_GET_DATA: 'W09F2310_GET_DATA',
    W09F2310_GET_DATA_SUCCESS: 'W09F2310_GET_DATA_SUCCESS',
    W09F2310_SIGNATURE: 'W09F2310_SIGNATURE',
    W09F2310_CANCEL_SIGNATURE: 'W09F2310_CANCEL_SIGNATURE',
    W09F2310_VIEW_REPORT: 'W09F2310_VIEW_REPORT',
};

export function getData(params, cb) {
    return {
        type: types.W09F2310_GET_DATA,
        params,
        cb,
    };
}

export function signature(params, cb) {
    return {
        type: types.W09F2310_SIGNATURE,
        params,
        cb,
    };
}

export function cancelSignature(params, cb) {
    return {
        type: types.W09F2310_CANCEL_SIGNATURE,
        params,
        cb,
    };
}

export function viewReport(params, cb) {
    return {
        type: types.W09F2310_VIEW_REPORT,
        params,
        cb,
    };
}
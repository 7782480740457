/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 13/09/2021
 * @Example
 */

import { types } from "./W09F2280_actions";

const initialState = {
    dataGrid: {}
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2280_GET_DATA_GRID_SUCCESS: {
            return {
                ...state,
                dataGrid: action.data
            };
        }
        default:
            return state;
    }
}
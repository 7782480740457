/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/4/2021
 * @Example
 */

export const types = {
    W09F3010_SELECTED_EMPLOYEES:   "W09F3010_LOAD_DYNAMIC_FIELD",
    W09F3010_LOAD_MEMBERS:         "W09F3010_LOAD_MEMBERS",
    W09F3010_LOAD_MEMBERS_SUCCESS: "W09F3010_LOAD_MEMBERS_SUCCESS",
    W09F3010_LEAVE_GROUP:          "W09F3010_LEAVE_GROUP",
    W09F3010_UPDATE_ROLE:          "W09F3010_UPDATE_ROLE",
    W09F3010_UPDATE_PIC_GROUP:     "W09F3010_UPDATE_PIC_GROUP",
    W09F3010_EDIT_DATA:            "W09F3010_EDIT_DATA",
    W09F3010_SAVE_DATA:            "W09F3010_SAVE_DATA",
    W09F3010_DELETE_DATA:          "W09F3010_DELETE_DATA",
    W09F3010_GET_FORM:             "W09F3010_GET_FORM",
    W09F3010_GET_FORM_SUCCESS:     "W09F3010_GET_FORM_SUCCESS",
};

export function loadForm (params, cb) {
    return {
        type: types.W09F3010_GET_FORM,
        params,
        cb
    };
}

export function loadMembers (params, cb) {
    return {
        type: types.W09F3010_LOAD_MEMBERS,
        params,
        cb
    };
}

export function selectedEmployees (params, cb) {
    return {
        type: types.W09F3010_SELECTED_EMPLOYEES,
        params,
        cb
    };
}

export function leaveGroup (params, cb) {
    return {
        type: types.W09F3010_LEAVE_GROUP,
        params,
        cb
    };
}

export function updateRole (params, cb) {
    return {
        type: types.W09F3010_UPDATE_ROLE,
        params,
        cb
    };
}

export function updatePicGroup (params, cb) {
    return {
        type: types.W09F3010_UPDATE_PIC_GROUP,
        params,
        cb
    };
}


import {types} from "./W09F2215_actions";

const initialState = {
    getGenders: [],
    getListRelative: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2215_GET_LIST_RELATIVE_SUCCESS:
            return {
                ...state,
                getListRelative: action.data
            };
        case types.W09F2215_GET_GENDER_SUCCESS:
            return {
                ...state,
                getGenders: action.data
            };
        default:
            return state;
    }
}
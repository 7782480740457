
import {types} from "./W09F4000_actions";

const initialState = {
    getDataCboPosition:  [],
};

export default function (state = initialState, action = {}) {
    if (action.type === types.W09F4000_GET_LIST_CBO_POSITION_SUCCESS) {
        return {
            ...state,
            getDataCboPosition: action.data
        };
    } else {
        return state;
    }
}
